import React from "react"
import { Container, Text, Div, Row, Col, Image, Anchor } from "atomize"

import fusionbase from "../images/logo-fusionbase.svg"
import behoerdenarzt from "../images/logo-behoerdenarzt.png"
import convaise from "../images/logo-convaise.svg"

import "./startups.css"

const list = [
  {
    name: "Convaise",
    url: "https://convaise.com/",
    logo: convaise,
    text: "Technische Umsetzung",
  },
  {
    name: "Fusionbase",
    url: "https://fusionbase.com/",
    logo: fusionbase,
    text: "Daten",
  },
  {
    name: "Behördenarzt",
    url: "https://www.behoerdenarzt.de/",
    logo: behoerdenarzt,
    text: "Medizinische Expertise",
  },
]

const Startups = () => (
  <Div tag="section" id="Startups" p={{ t: "6rem" }}>
    <Container>
      <Text
        textSize={{ xs: "title", sm: "heading", md: "display1" }}
        textWeight="500"
        fontFamily="secondary"
        textAlign="center"
        m={{ b: "2rem" }}
      >
        Ein Gemeinschaftsprojekt von
      </Text>
      <Div d="flex" flexDir="row" justify="space-between">
        <Row w="100%" align="center">
          {list.map(item => (
            <Col size={{ xs: 12, sm: 6, lg: 4 }} key={item.name}>
              <Div
                textAlign="center"
                h="100%"
                m={{ b: { xs: "3rem", sm: "3rem" } }}
              >
                <Anchor
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    alt={"Logo " + item.name}
                    src={item.logo}
                    w="100%"
                    maxW="12rem"
                    className="logos"
                    cursor="pointer"
                    m={{ t: "auto", b: "auto" }}
                  />
                  <Text textSize="body" justify="bottom" m={{ b: "0px" }}>
                    {item.text}
                  </Text>
                </Anchor>
              </Div>
            </Col>
          ))}
        </Row>
      </Div>
    </Container>
  </Div>
)

export default Startups
